<template>
  <div style="width: 100%">
    <div class="favorites flex-1 overflow-hidden px-6 pb-8" style="width: 100%">
      <div class="list-actions py-4 px-3 d-flex">
        <div class="d-flex list-header align-baseline">
          <h2>Suosikit: <span> </span></h2>
          <div class="ml-4">
            {{ this.$store.state.favorite.favoriteItems.length }} kpl
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn-toggle v-model="toggle_exclusive" mandatory>
          <v-btn @click="makeList('grid')">
            <v-icon>mdi-grid-large</v-icon>
          </v-btn>

          <v-btn @click="makeList('list')">
            <v-icon>mdi-view-list</v-icon>
          </v-btn>
        </v-btn-toggle>
      </div>

      <div class="list-container d-flex flex-wrap mx-auto" :class="list">
        <template v-for="(item, i) in this.$store.state.favorite.favoriteItems">
          <ProductCard :product="item" :key="i" />
        </template>
      </div>

      <RecentItems />
    </div>
    <Footer />
  </div>
</template>

<script>
import ProductCard from "@/components/ProductCard";
import RecentItems from "@/components/RecentItems";
import Footer from "@/components/Footer";

export default {
  components: { ProductCard, RecentItems, Footer },
  data() {
    return {
      toggle_exclusive: undefined,
      list: "",
    };
  },
  methods: {
    makeList: function (option) {
      if (option === "grid") {
        this.list = "";
      } else if (option === "list") {
        this.list = "card-list";
      }
    },
  },
};
</script>
